import React from "react"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Floral from "../components/floral"
import Hero from "../components/hero"
import CTA from "../components/cta"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <Hero noImage>
      <Intro>Terms of Use</Intro>
    </Hero>
    <Floral withHero>
      <CTA tagName="h2">
        DO NOT USE THIS SERVICE FOR AN EMERGENCY. <br />
        IF YOU EXPERIENCE AN EMERGENCY, CALL 911 IMMEDIATELY.
      </CTA>
      <div className="col-2">
        <p>
          Please read these Terms of Use carefully (&quot;Terms&quot;). These
          Terms govern and apply to your access and use of the programs offered
          by AngelShot Ltd (&ldquo;AngelShot&rdquo;, &quot;Company&quot;,
          &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;) via the
          AngelShot website <Link to="/">https://www.angelshot.ltd/</Link>{" "}
          (&ldquo;Site&rdquo;) and AngelShot text messaging service
          (&ldquo;AngelBot&rdquo;) (all hereinafter collectively,
          &quot;AngelShot Services&quot;, &ldquo;Services&rdquo;) and any
          information, text, graphics, photos or other materials uploaded,
          downloaded or appearing on our Service (collectively referred to as
          &quot;Content&quot;). By accessing or using our Services, you agree to
          comply with and to be bound by all of the terms and conditions
          described in these Terms. If you do not agree to all of these terms
          and conditions, you are not authorized to use our Services. The terms
          &ldquo;You&quot;, &quot;Your,&rdquo; and &ldquo;Yourself&rdquo; refer
          to the individual user of our Services.
        </p>
        <h2 className="subheader">Intellectual Property Rights</h2>
        <p>
          Unless otherwise indicated, our Services are our proprietary property
          and all source code, databases, functionality, software, website
          designs, audio, video, text, photographs, and graphics on our Services
          (collectively, the &ldquo;Content&rdquo;) and the trademarks, service
          marks, and logos contained therein (the &ldquo;Marks&rdquo;) are owned
          or controlled by us or licensed to us, and are protected by copyright
          and trademark laws and various other intellectual property rights and
          unfair competition laws of the United States, international copyright
          laws, and international conventions. The Content and the Marks are
          provided on our Services &ldquo;AS IS&rdquo; for your information and
          personal use only. Except as expressly provided in these Terms of Use,
          no part of our Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p>
          Provided that you are eligible to use our Services, you are granted a
          limited license to access and use our Services and to download or
          print a copy of any portion of the Content to which you have properly
          gained access solely for your personal, non-commercial use. We reserve
          all rights not expressly granted to you in and to our Services, the
          Content and the Marks.
        </p>
        <h2 className="subheader">User Representations</h2>
        <p>
          By using our services, you represent and warrant that: (1) all
          information you submit will be true, accurate, current, and complete;
          (2) you will maintain the accuracy of such information and promptly
          update such information as necessary; (3) you have the legal capacity
          and you agree to comply with these Terms of Use; (4) you are not under
          the age of 13; (5) you are not a minor in the jurisdiction in which
          you reside, or if a minor, you have received parental permission to
          use our Services; (6) you will not access our Services through
          automated or non-human means, whether through a bot, script, or
          otherwise; (7) you will not use our Services for any illegal or
          unauthorized purpose; and (8) your use of our Services will not
          violate any applicable law or regulation.
        </p>
        <h2 className="subheader">User Registration</h2>
        <p>
          You may be required to register with our Services. You agree to keep
          your password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>
        <h2 className="subheader">Prohibited Activities</h2>
        <p>
          You may not access or use our Services for any purpose other than that
          for which we make our Services available. Our Services may not be used
          in connection with any commercial endeavors.
        </p>
        <p>As a user of our Services, you agree not to:</p>
        <ol>
          <li>
            Systematically retrieve data or other content from our Services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of our Services, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of our Services and/or the Content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or our
            Services.
          </li>
          <li>
            Use any information obtained from our Services in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Use our Services in a manner inconsistent with any applicable laws
            or regulations.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party&rsquo;s uninterrupted use of our Services
            or modifies, impairs, disrupts, alters, or interferes with the use,
            features, functions, operation, or maintenance of our Services.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web
            bugs, cookies, or other similar devices (sometimes referred to as
            &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo;
            or &ldquo;pcms&rdquo;).
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on our Services.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of our Services to you.
          </li>
          <li>
            Attempt to bypass any measures of our Services designed to prevent
            or restrict access to the Site, or any portion of our Services.
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of our Services.
          </li>
          <li>
            Make any unauthorized use of our Services, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            Use our Services as part of any effort to compete with us or
            otherwise use our Services and/or the Content for any
            revenue-generating endeavor or commercial enterprise.
          </li>
        </ol>
        <h2 className="subheader">User Generated Contributions</h2>
        <p>
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          &quot;Contributions&quot;). Contributions may be viewable by other
          users of the Site and through third-party websites. As such, any
          Contributions you transmit may be treated as non-confidential and
          non-proprietary. When you create or make available any Contributions,
          you thereby represent and warrant that:
        </p>
        <ol>
          <li>
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Site, and other users of the Site to use your Contributions
            in any manner contemplated by the Site and these Terms of Use.
          </li>
          <li>
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these Terms of Use.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions do not advocate the violent overthrow of any
            government or incite, encourage, or threaten physical harm against
            another.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </li>
          <li>
            Your Contributions do not violate any federal or state law
            concerning child pornography, or otherwise intended to protect the
            health or well-being of minors;
          </li>
          <li>
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </li>
        </ol>
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
        <h2 className="subheader">Contribution License</h2>
        <p>
          By posting your Contributions to any part of the Site, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid,
          worldwide right, and license to host, use, copy, reproduce, disclose,
          sell, resell, publish, broadcast, retitle, archive, store, cache,
          publicly perform, publicly display, reformat, translate, transmit,
          excerpt (in whole or in part), and distribute such Contributions
          (including, without limitation, your image and voice) for any purpose,
          commercial, advertising, or otherwise, and to prepare derivative works
          of, or incorporate into other works, such Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.
        </p>
        <p>
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </p>
        <p>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Site. You are
          solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </p>
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions.
        </p>
        <h2 className="subheader">Submissions</h2>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding our Services
          (&quot;Submissions&quot;) provided by you to us are non-confidential
          and shall become our sole property. We shall own exclusive rights,
          including all intellectual property rights, and shall be entitled to
          the unrestricted use and dissemination of these Submissions for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </p>
        <h2 className="subheader">Third-party Website And Content</h2>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites (&quot;Third-Party Websites&quot;) as well as articles,
          photographs, text, graphics, pictures, designs, music, sound, video,
          information, applications, software, and other content or items
          belonging to or originating from third parties (&quot;Third-Party
          Content&quot;). Such Third-Party Websites and Third-Party Content are
          not investigated, monitored, or checked for accuracy, appropriateness,
          or completeness by us, and we are not responsible for any Third-Party
          Websites accessed through the Site or any Third-Party Content posted
          on, available through, or installed from the Site, including the
          content, accuracy, offensiveness, opinions, reliability, privacy
          practices, or other policies of or contained in the Third-Party
          Websites or the Third-Party Content. Inclusion of, linking to, or
          permitting the use or installation of any Third-Party Websites or any
          Third-Party Content does not imply approval or endorsement thereof by
          us. If you decide to leave the Site and access the Third-Party
          Websites or to use or install any Third-Party Content, you do so at
          your own risk, and you should be aware these Terms of Use no longer
          govern. You should review the applicable terms and policies, including
          privacy and data gathering practices, of any website to which you
          navigate from the Site or relating to any applications you use or
          install from the Site. Any purchases you make through Third-Party
          Websites will be through other websites and from other companies, and
          we take no responsibility whatsoever in relation to such purchases
          which are exclusively between you and the applicable third party. You
          agree and acknowledge that we do not endorse the products or services
          offered on Third-Party Websites and you shall hold us harmless from
          any harm caused by your purchase of such products or services.
          Additionally, you shall hold us harmless from any losses sustained by
          you or harm caused to you relating to or resulting in any way from any
          Third-Party Content or any contact with Third-Party Websites.
        </p>
        <h2 className="subheader">Services Management</h2>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor our
          Services for violations of these Terms of Use; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Terms of Use, including without limitation, reporting
          such user to law enforcement authorities; (3) in our sole discretion
          and without limitation, refuse, restrict access to, limit the
          availability of, or disable (to the extent technologically feasible)
          any of your Contributions or any portion thereof; (4) in our sole
          discretion and without limitation, notice, or liability, to remove
          from our Services or otherwise disable all files and content that are
          excessive in size or are in any way burdensome to our systems; and (5)
          otherwise manage our Services in a manner designed to protect our
          rights and property and to facilitate the proper functioning of our
          Services.
        </p>
        <h2 className="subheader">Privacy Policy</h2>
        <p>
          We care about data privacy and security. Please review our Privacy
          Policy: <Link to="/privacy">https://www.angelshot.ltd/privacy</Link>.
          By using our Services, you agree to be bound by our Privacy Policy,
          which is incorporated into these Terms of Use. Please be advised our
          Services are hosted in the United States. If you access our Services
          from any other region of the world with laws or other requirements
          governing personal data collection, use, or disclosure that differ
          from applicable laws in the United States, then through your continued
          use of our Services, you are transferring your data to the United
          States, and you agree to have your data transferred to and processed
          in the United States. Further, we do not knowingly accept, request, or
          solicit information from children or knowingly market to children.
          Therefore, in accordance with the U.S. Children&rsquo;s Online Privacy
          Protection Act, if we receive actual knowledge that anyone under the
          age of 13 has provided personal information to us without the
          requisite and verifiable parental consent, we will delete that
          information from our Services as quickly as is reasonably practical.
        </p>
        <h2 className="subheader">Copyright Infringements</h2>
        <p>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Site infringes upon any
          copyright you own or control, please immediately notify us using the
          contact information provided below (a &ldquo;Notification&rdquo;). A
          copy of your Notification will be sent to the person who posted or
          stored the material addressed in the Notification. Please be advised
          that pursuant to applicable law you may be held liable for damages if
          you make material misrepresentations in a Notification. Thus, if you
          are not sure that material located on or linked to by the Site
          infringes your copyright, you should consider first contacting an
          attorney.
        </p>
        <h2 className="subheader">Term And Termination</h2>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          our Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
          USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF OUR SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR BREACH OF ANY
          REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE
          OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN OUR SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT
          OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
          SOLE DISCRETION.
        </p>
        <h2 className="subheader">Modifications And Interruptions</h2>
        <p>
          We reserve the right to change, modify, or remove the contents of our
          Services at any time or for any reason at our sole discretion without
          notice. We also reserve the right to modify or discontinue all or part
          of our Services without notice at any time. We will not be liable to
          you or any third party for any modification, price change, suspension,
          or discontinuance of our Services.
        </p>
        <p>
          We cannot guarantee our Services will be available at all times. We
          may experience hardware, software, or other problems or need to
          perform maintenance related to our Services, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify our Services
          at any time or for any reason without notice to you. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience
          caused by your inability to access or use our Services during any
          downtime or discontinuance of our Services. Nothing in these Terms of
          Use will be construed to obligate us to maintain and support our
          Services or to supply any corrections, updates, or releases in
          connection therewith unless required by law.
        </p>
        <h2 className="subheader">Governing Law</h2>
        <p>
          These Terms of Use and your use of our Services are governed by and
          construed in accordance with the laws of the State of New York
          applicable to agreements made and to be entirely performed within the
          State of New York, without regard to its conflict of law principles.
        </p>
        <h2 className="subheader">Disclaimer Of Warranty</h2>
        <p>
          THE CONTENT PROVIDED VIA OUR SERVICES IS PROVIDED AS A SERVICE TO
          MEMBERS OF THE PUBLIC. INFORMATION PRESENTED VIA OUR SERVICES IS
          PROVIDED FOR INFORMATIONAL PURPOSES ONLY. NO INFORMATION PRESENTED ON
          OUR SERVICES CONSTITUTES LEGAL ADVICE, NOR DOES IT CREATE AN
          ATTORNEY-CLIENT RELATIONSHIP BETWEEN THE ANGELSHOT AND ANY OTHER
          PARTY.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT OUR SERVICES AND THE CONTENT THEREIN
          ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;
          BASIS. NONE OF ANGELSHOT, ANY OF ITS MEMBERS, AFFILIATES OR THEIR
          RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS (COLLECTIVELY THE
          &quot;ANGELSHOT PARTIES&quot;) GUARANTEES THE ACCURACY, COMPLETENESS,
          OR USEFULNESS OF ANY OF THE CONTENT.
        </p>
        <p>
          NONE OF ANGELSHOT PARTIES WARRANTS THAT OUR SERVICES WILL BE
          UNINTERRUPTED OR ERROR FREE OR THAT OUR SERVICES, ITS SERVERS OR ANY
          FILES AVAILABLE FOR DOWNLOADING THROUGH THIS SITE ARE FREE OF COMPUTER
          VIRUSES OR OTHER HARMFUL ELEMENTS. YOU EXPRESSLY AGREE THAT THE ENTIRE
          RISK AS TO THE QUALITY AND PERFORMANCE OF OUR SERVICES AND THE
          ACCURACY OR COMPLETENESS OF THE CONTENT IS ASSUMED SOLELY BY YOU.
        </p>
        <p>
          NONE OF THE ANGELSHOT PARTIES MAKES ANY, AND HEREBY SPECIFICALLY
          DISCLAIMS ANY, REPRESENTATIONS, ENDORSEMENTS, GUARANTEES, OR
          WARRANTIES, EXPRESS OR IMPLIED, REGARDING OUR SERVICES OR ANY CONTENT,
          INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. WITHOUT LIMITING THE
          GENERALITY OF THE FOREGOING, ALL OF THE ANGELSHOT PARTIES DISCLAIM ANY
          WARRANTIES WITH RESPECT TO ANY RESULTS THAT MAY BE OBTAINED FROM THE
          USE OF OUR SERVICES.
        </p>
        <h2 className="subheader">Limitations Of Liability</h2>
        <p>
          UNDER NO CIRCUMSTANCES WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
          BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON
          INFORMATION OBTAINED THROUGH THE CONTENT ON THE WEB SITE. IT IS YOUR
          RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS, OR USEFULNESS
          OF ANY INFORMATION OR ANY CONTENT AVAILABLE THROUGH THE WEB SITE.
        </p>
        <p>
          IN NO EVENT SHALL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
          ARISING OUT OF OR RELATING TO THE CONTENT, SERVICE, OR THIS AGREEMENT,
          WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.
        </p>
        <p>
          BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          LIABILITY FOR NEGLIGENCE, CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
          JURISDICTIONS OUR LIABILITY IS LIMITED TO THE GREATEST EXTENT
          PERMITTED BY LAW.
        </p>
        <p>
          YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH OUR SERVICES
          IS TO STOP USING OUR SERVICES.
        </p>
        <h2 className="subheader">Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys&rsquo; fees and
          expenses, made by any third party due to or arising out of: (1) your
          Contributions; (2) use of our Services; (3) breach of these Terms of
          Use; (4) any breach of your representations and warranties set forth
          in these Terms of Use; (5) your violation of the rights of a third
          party, including but not limited to intellectual property rights; or
          (6) any overt harmful act toward any other user of our Services with
          whom you connected via our Services. Notwithstanding the foregoing, we
          reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and you agree to cooperate, at your expense, with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </p>
        <h2 className="subheader">User Data</h2>
        <p>
          We will maintain certain data that you transmit to our Services for
          the purpose of managing the performance of our Services, as well as
          data relating to your use of our Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          our Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.
        </p>
        <h2 className="subheader">
          Electronic Communications, Transactions, And Signatures
        </h2>
        <p>
          Visiting the Site, using AngelBot, sending us emails, and completing
          online forms constitute electronic communications. You consent to
          receive electronic communications, and you agree that all agreements,
          notices, disclosures, and other communications we provide to you
          electronically, via email, text messaging, and on the Site, satisfy
          any legal requirement that such communication be in writing. YOU
          HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
          AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
          AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US, VIA THE
          SITE, OR VIA ANGELBOT. You hereby waive any rights or requirements
          under any statutes, regulations, rules, ordinances, or other laws in
          any jurisdiction which require an original signature or delivery or
          retention of non-electronic records, or to payments or the granting of
          credits by any means other than electronic means.
        </p>
        <h2 className="subheader">California Users And Residents</h2>
        <p>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
        <h2 className="subheader">Miscellaneous</h2>
        <p>
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to our Services constitute the entire agreement
          and understanding between you and us. Our failure to exercise or
          enforce any right or provision of these Terms of Use shall not operate
          as a waiver of such right or provision. These Terms of Use operate to
          the fullest extent permissible by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be
          responsible or liable for any loss, damage, delay, or failure to act
          caused by any cause beyond our reasonable control. If any provision or
          part of a provision of these Terms of Use is determined to be
          unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Terms of Use and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms of
          Use or use of our Services. You agree that these Terms of Use will not
          be construed against us by virtue of having drafted them. You hereby
          waive any and all defenses you may have based on the electronic form
          of these Terms of Use and the lack of signing by the parties hereto to
          execute these Terms of Use.
        </p>
        <h2 className="subheader">Contact Us</h2>
        <p>
          In order to resolve a complaint regarding our Services or to receive
          further information regarding use of our Services, please contact us
          at: hi@angelshot.ltd
        </p>
      </div>
    </Floral>
  </Layout>
)
